// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCeR-hgERJrcE-97yfy1iE2Q11g4MUo-EI",
  authDomain: "fireship-demo-1aedd.firebaseapp.com",
  projectId: "fireship-demo-1aedd",
  storageBucket: "fireship-demo-1aedd.appspot.com",
  messagingSenderId: "1028957174052",
  appId: "1:1028957174052:web:c63603720628884c069bb1"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);