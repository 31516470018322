import React from 'react';
import logo from './logo.svg';
import './App.css';
import { getFirestore, collection, getDocs, Firestore} from 'firebase/firestore/lite';

import {app} from "./configuration";

const db = getFirestore(app);

// Get a list of cities from your database
async function getCities(db: Firestore) {
  const citiesCol = collection(db, 'cities');
  const citySnapshot = await getDocs(citiesCol);
  const data = citySnapshot.docs.map(doc => doc.data());
  console.log(data);
  return data;
}


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <button onClick={() => getCities(db)}>Get Cities</button>
      </header>
    </div>
  );
}

export default App;
